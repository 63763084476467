import type {
    BikeFinderQuery_Collection_Products_Items,
    ShopContentQuery_ShopContent,
    ShopContentQuery_ShopContentItem,
} from '~/@types/nestedGraphqlTypes';
import type { MenuItem, VariantQuery } from '~/graphql/generated';
// const langCountryMatch = /^[A-Za-z]{2}[_-]([A-Za-z0-9]{2,3})?/;

// const days = 60;

export type LoadShopInfoReturnType = (ShopContentQuery_ShopContentItem | undefined)[];

export type NotificationPayload = {
    type: string;
    message: string;
    sticky: boolean;
};

export type BikeAppStore = TSArrayElement<NonNullable<BikeFinderQuery_Collection_Products_Items>> & { range: string };

type BikesByRangeType = {
    code: string;
    sort: number;
    bikes: string[];
};

type AppStateType = {
    theme: string;
    website: WebsiteQuery_Website | null;
    shopInfo: ShopContentQuery_ShopContent | null;
    menuItems: MenuItem[] | null | undefined;
    pageCache: PageByUrlQuery_PageByUrl | null;
    preview: boolean;
    notificationQueue: NotificationPayload[];
    bikefinder: any;
    bikefinderLinks: any[] | undefined | null;
    bikes: BikeAppStore[];
    bikesByRange: BikesByRangeType[];
    notifications: any[] | null; // UNKNOWN_TYPE
    orderCodes: any[]; // UNKNOWN_TYPE
    ci: 'v1' | 'v2';
    variantData: VariantQuery_ShopVariant[];
    hasPendingPromises: boolean;
    clientIP: string;
    hasH1: boolean;
    menuOffset: boolean;
};

const getDefaultAppState = (): AppStateType => {
    return {
        theme: 'light',
        // for testing
        website: null,
        shopInfo: null,
        menuItems: null,
        pageCache: null,
        // TODO: - this set's the preview by default
        preview: false,
        notificationQueue: [],
        bikefinder: null,
        bikefinderLinks: [],
        bikes: [],
        bikesByRange: [],
        // these are the notifications to be displayed
        notifications: null,
        orderCodes: [],
        ci: 'v2',
        variantData: [],
        hasPendingPromises: false,
        clientIP: '',
        hasH1: false,
        menuOffset: true,
    };
};

export type UnitsType = {
    metric: boolean;
    imperial: boolean;
};

// https://pinia.vuejs.org/core-concepts/#setup-stores
export const useAppStore = defineStore('woom-store-app', {
    state: () => getDefaultAppState(),

    getters: {
        getNotifications: (state) => state.notificationQueue,
        websiteUpsell: (state) => {
            return state.website?.shop?.upsell || [];
        },
        seo: (state): WebsiteQuery_Website_SEO_NonNullable => {
            const seo = state.website?.seo || {
                image: null,
                title: 'woom bikes',
                description: '',
            };

            return seo;
        },
        units: (state): UnitsType => {
            const units = state.website?.shop?.units || null;
            if (units) {
                return {
                    metric: units.includes('metric'),
                    imperial: units.includes('imperial'),
                };
            }
            return {
                imperial: true,
                metric: true,
            };
        },
        footer: (state) => {
            return state.website?.footer || null;
        },
        navbar: (state) => {
            return state.website?.navbar || null;
        },
        menuItemById: (state) => {
            return (id: string) => {
                return state.website?.navbar?.references?.find((m) => m.id === id) as MenuItem | undefined;
            };
        },
        defaultSeo: (state) => {
            // return the empty object
            return state.website?.seo || {};
        },
        socialLinks: (state) => {
            return state.website?.social || {};
        },
        hasPageCache: (state) => {
            return (url: string, locale: string) => {
                const ru = state.pageCache?.url.url || null;
                const rl = state.pageCache?.url.locale || null;
                return ru === url && rl === locale;
            };
        },
        howItWorksLink: (state) => {
            return getBikeFinderRefLink(state.bikefinder.how_it_works, state.bikefinderLinks);
        },
        bikeFinderLink: (state) => {
            return getBikeFinderRefLink(state.bikefinder.link, state.bikefinderLinks);
        },
        getReference:
            (state): RefLookupFunction =>
            (id: string, type = 'references') => {
                const pageCacheRefs = state.pageCache?.refs;
                if (pageCacheRefs) return getItemById(id, (pageCacheRefs as any)?.[type]);
            },
    },

    actions: {
        async fetchNotifications(locale: string) {
            // const current = state.value.website?.locale || null;
            const current = this.website?.locale || null;

            if (Array.isArray(this.notifications) && this.notifications.length > 0 && current === locale) {
                return;
            }
            try {
                const { enabled } = usePreviewMode();
                const response = await useNotificationsQueryData({
                    variables: {
                        locale,
                        preview: enabled.value,
                    },
                }).promise;

                // store the website object
                // console.log('got website response', JSON.stringify(get(response, 'data.website.navbar', null), null, ' '))
                this.notifications = response?.data?.getNotifications || [];
            } catch (err) {
                console.log('notifications error', err); // eslint-disable-line no-console
                throw err;
            }
        },

        clear() {
            this.menuItems = null;
            this.notifications = null;
            this.shopInfo = null;
            this.bikefinder = null;
            this.bikefinderLinks = [];
            this.bikes = [];
            this.bikesByRange = [];
            this.pageCache = null;
        },
        /**
         * @deprecated This method has been deprecated in favor of the "usePreviewMode" composable from nuxt
         */
        setPreview(preview: boolean) {
            console.log('setPreview', preview);
            if (this.preview !== preview) this.clear();
            this.preview = preview;

            if (preview) {
                this.enqueueNotification({
                    type: NotificationType.INFO,
                    message: 'The Woom Site is in PREVIEW mode',
                    sticky: false,
                });
            }
        },
        enqueueNotification(notification: NotificationPayload) {
            this.notificationQueue.push(notification);
        },
        dequeueNotification() {
            if (this.notificationQueue.length) this.notificationQueue.shift();
            return this.notificationQueue?.[0] ?? null;
        },

        saveExtendedPayloadData<T>(data: ExtendedApolloQueryResult<T> | undefined) {
            if (!data) {
                return;
            }
            if (data.variantData?.data) {
                // add new entries, or replace existing ones. Never clear or replace the entire array
                const reducedVariantData = (data.variantData.data || []).reduce(
                    (previousValue: VariantQuery_ShopVariant[], currentValue: VariantQuery, _currentIndex: number, _array: VariantQuery[]) => {
                        const currentValueInner = currentValue.shopVariant;

                        const filtered = previousValue.filter(
                            (x) => x?.product.id !== currentValueInner?.product.id || x?.variant.id !== currentValueInner?.variant.id,
                        );

                        return [...filtered, currentValueInner];
                    },
                    this.variantData,
                );

                this.variantData = reducedVariantData;
            }

            // add new entries, or replace existing ones. Never clear or replace the entire array
            if (data.shopData?.data) {
                const reducedShopData = data.shopData.data.shopContent.reduce(
                    (
                        previousValue: ShopContentQuery_ShopContentItem[],
                        currentValue: ShopContentQuery_ShopContentItem,
                        _currentIndex: number,
                        _array: ShopContentQuery_ShopContentItem[],
                    ) => {
                        const filtered = previousValue.filter((x) => x.id !== currentValue.id);

                        return [...filtered, currentValue];
                    },
                    this.shopInfo || [],
                );

                this.shopInfo = reducedShopData;
            }
        },
        async checkWebsite({ locale, iso2Country, iso2Lang }: { locale: Ref<string>; iso2Country: Ref<string>; iso2Lang: Ref<string> }) {
            // console.log('checkWebsite called')
            // check if we have a website element stored
            const current = this.website?.locale;

            // check if the locales are matching
            if (current !== locale.value) {
                // fetch the website object
                // console.log('load website', locale)

                this.clear();

                const { enabled } = usePreviewMode();
                const results = await Promise.all([
                    useWebsiteQueryData({
                        variables: {
                            locale: locale.value,
                            codename: 'website',
                            preview: enabled.value,
                        },
                    }).promise,
                    this.getBikefinder({ locale }),
                    this.fetchNotifications(locale.value),
                ]);

                const websiteQueryResult = results[0];

                const website = websiteQueryResult?.data?.website;

                if (website) {
                    this.website = website;
                    const navbar = website.navbar;
                    this.menuItems = navbar?.items
                        ?.map((itemId) => {
                            // TODO: extract into util func
                            return navbar?.references?.find(({ id }) => id === itemId) as MenuItem | undefined;
                        })
                        .filter(isDefined);
                    this.saveExtendedPayloadData(websiteQueryResult);
                    return true;
                }
            } else {
                return true;
            }
        },

        async loadPageByUrl({
            locale,
            iso2Country,
            iso2Lang,
            url,
        }: {
            locale: Ref<string>;
            iso2Country: Ref<string>;
            iso2Lang: Ref<string>;
            url: string;
        }): Promise<PageByUrlQuery_PageByUrl> {
            await this.checkWebsite({ locale, iso2Country, iso2Lang });
            // fix for urls that end with a slash (urls in the database are without trailing slash)
            if (url.length > 1 && url.substring(url.length - 1) === '/') {
                url = url.substring(0, url.length - 1);
            }

            const { enabled } = usePreviewMode();

            if (this.hasPageCache(url, locale.value) && !enabled.value) {
                // console.log('got page in cache', this.pageCache); // eslint-disable-line no-console
                return this.pageCache;
            } else {
                const data = await getPageByUrlQueryData({
                    locale: locale.value,
                    url,
                    preview: enabled.value,
                    full: true,
                });
                // console.log('fetch page', locale, url, preview, data); // eslint-disable-line no-console

                // console.log('pageByUrlQuery result data', data); // eslint-disable-line no-console

                const pd: PageByUrlQuery_PageByUrl = data?.data.pageByUrl || null;
                this.pageCache = pd;
                this.saveExtendedPayloadData(data);
                // console.log('pd', pd); // eslint-disable-line no-console
                return pd;
            }
            // })

            // });
        },

        setBikes(bikes: BikeFinderQuery_Collection_Products_Items) {
            const ranges: {
                code: string;
                sort: number;
                bikes: string[];
            }[] = [];
            this.bikes = [];
            if (bikes) {
                const types = ['original', 'up', ['off', 'off_air'], 'now', 'explore'];
                const typesSort = [1, 4, 3, 2, 0];
                for (const bike of bikes) {
                    if (bike && bike.content && bike.content.category) {
                        // first get the base type of the bike
                        const type = types.filter((p) => {
                            if (Array.isArray(p)) {
                                for (const t of p) {
                                    if ((bike.content?.category || []).includes(t)) {
                                        return true;
                                    }
                                }
                            } else if ((bike.content?.category || []).includes(p)) {
                                return true;
                            }
                            return false;
                        });
                        if (type && type.length === 1) {
                            const idx = types.indexOf(type[0]);
                            const sort = typesSort[idx];
                            const t = Array.isArray(type[0]) ? type[0].join('_') : type[0];
                            const exists = ranges.find((r) => r.code === t);
                            if (exists) {
                                exists.bikes.push(bike.content.id);
                            } else {
                                ranges.push({
                                    code: t,
                                    sort,
                                    bikes: [bike.content.id],
                                });
                            }

                            this.bikes.push({
                                ...bike,
                                range: t,
                            });
                        }
                    }
                }
            }
            for (const b of ranges) {
                b.bikes = b.bikes.sort((a, b) => {
                    // natural order, remove the id prefix. if the handle starts with woom-, remove that as well
                    let aName = a.substring(a.indexOf('shop_product:') + 13);
                    let bName = b.substring(b.indexOf('shop_product:') + 13);
                    if (aName.indexOf('woom-') === 0) {
                        aName = aName.substring(5);
                    }
                    if (bName.indexOf('woom-') === 0) {
                        bName = bName.substring(5);
                    }
                    // add a sorting exception for woom-1 and woom-1-plus
                    if (aName.includes('1-plus') && bName.includes('1')) return 1;
                    if (aName.includes('1') && bName.includes('1-plus')) return -1;

                    if (aName < bName) return -1;
                    if (aName > bName) return 1;

                    return 0;
                });
            }
            // now sort the range
            this.bikesByRange = ranges.sort((a, b) => {
                return a.sort - b.sort;
            });
        },
        /**
         * load the bikefinder collection and store it localy
         */
        async getBikefinder({ locale }: { locale: Ref<string> }): Promise<BikeFinderQuery_Collection_Products_Items> {
            if (this.bikefinder) {
                return this.bikefinder;
            }

            const [language, country] = locale.value.split('_');
            const { enabled } = usePreviewMode();

            const id = `${country.toLowerCase()}:${language.toLowerCase()}:collection:bikefinder`;

            const data = await useBikeFinderQueryData({
                variables: {
                    id,
                    locale: unref(locale),
                    preview: enabled.value,
                },
            }).promise;

            // console.log('got bikefinder data', data);

            const bf = data?.data?.bikefinder;
            if (bf) {
                this.bikefinder = bf.bikefinder;
                this.bikefinderLinks = bf.links;
            }

            const bikes = data?.data.collection?.products.items;
            // const bikes = get(d, 'products.items', []);
            if (bikes) {
                this.setBikes(bikes);
            } else {
                logSentry(`no data for bikefinder with id = ${id}`, { level: 'warning' });
            }
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot));
}
