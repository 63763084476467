<script setup lang="ts">
    useHead({
        bodyAttrs: {
            class: 'overflow-x-hidden',
        },
        htmlAttrs: {
            class: 'scroll-smooth',
        },
    });
</script>

<template>
    <div id="vue-root"></div>
    <div id="vue-root-header"></div>
    <nuxt-layout />
</template>
