import revive_payload_client_QNLrC6bjW3 from "/usr/src/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.41_eslint@8.57.0_ioredis@5.4.1_magicast@0_sp6oxyoamdsk3zyrb4wztbq7li/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ZbS0mJEhuy from "/usr/src/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.41_eslint@8.57.0_ioredis@5.4.1_magicast@0_sp6oxyoamdsk3zyrb4wztbq7li/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_uE4yw9EV8Q from "/usr/src/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.41_eslint@8.57.0_ioredis@5.4.1_magicast@0_sp6oxyoamdsk3zyrb4wztbq7li/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_WgcQXFgtxY from "/usr/src/app/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.19.0_vite@5.3.4_@types+node@18.19.41_sass@1.7_jqmlqwerkz5hdtbepzact7sdoi/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_u8kmtbNTi2 from "/usr/src/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.41_eslint@8.57.0_ioredis@5.4.1_magicast@0_sp6oxyoamdsk3zyrb4wztbq7li/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_N9x8nwSoa3 from "/usr/src/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.41_eslint@8.57.0_ioredis@5.4.1_magicast@0_sp6oxyoamdsk3zyrb4wztbq7li/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_7zzE6wtDdL from "/usr/src/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.41_eslint@8.57.0_ioredis@5.4.1_magicast@0_sp6oxyoamdsk3zyrb4wztbq7li/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_DGqmpVUMUF from "/usr/src/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.41_eslint@8.57.0_ioredis@5.4.1_magicast@0_sp6oxyoamdsk3zyrb4wztbq7li/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_7CIDSmoMzQ from "/usr/src/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.4_rollup@4.19.0_typescript@5.5.3_vue@3.4.33_typescript@5.5.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_CTmqDutZGS from "/usr/src/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.41_eslint@8.57.0_ioredis@5.4.1_magicast@0_sp6oxyoamdsk3zyrb4wztbq7li/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_2X2JI7KEvm from "/usr/src/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.4_rollup@4.19.0_vue@3.4.33_typescript@5.5.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_mS0b2eukiv from "/usr/src/app/node_modules/.pnpm/@nuxt+image@1.4.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.19.0/node_modules/@nuxt/image/dist/runtime/plugin.mjs";
import _01_hydrate_url_fix_client_cP4U89wC7W from "/usr/src/app/plugins/01.hydrate-url-fix.client.ts";
import _02_scroll_client_NR1mMAk4pH from "/usr/src/app/plugins/02.scroll.client.ts";
import event_emitter_3veoQFF54T from "/usr/src/app/plugins/event-emitter.ts";
import gtm_client_OzmBuHLRIb from "/usr/src/app/plugins/gtm.client.ts";
import helpers_mgWmzbOuLB from "/usr/src/app/plugins/helpers.ts";
import helpscout_client_EOdKpmWm8J from "/usr/src/app/plugins/helpscout.client.ts";
import klaviyo_aSAUWaoYmN from "/usr/src/app/plugins/klaviyo.ts";
import request_context_sSZWmJ36U3 from "/usr/src/app/plugins/request-context.ts";
import sentry_client_shVUlIjFLk from "/usr/src/app/plugins/sentry.client.ts";
import webgains_client_Lxdy7sDJ3W from "/usr/src/app/plugins/webgains.client.ts";
export default [
  revive_payload_client_QNLrC6bjW3,
  unhead_ZbS0mJEhuy,
  router_uE4yw9EV8Q,
  _0_siteConfig_WgcQXFgtxY,
  payload_client_u8kmtbNTi2,
  navigation_repaint_client_N9x8nwSoa3,
  check_outdated_build_client_7zzE6wtDdL,
  chunk_reload_client_DGqmpVUMUF,
  plugin_vue3_7CIDSmoMzQ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_CTmqDutZGS,
  i18n_2X2JI7KEvm,
  plugin_mS0b2eukiv,
  _01_hydrate_url_fix_client_cP4U89wC7W,
  _02_scroll_client_NR1mMAk4pH,
  event_emitter_3veoQFF54T,
  gtm_client_OzmBuHLRIb,
  helpers_mgWmzbOuLB,
  helpscout_client_EOdKpmWm8J,
  klaviyo_aSAUWaoYmN,
  request_context_sSZWmJ36U3,
  sentry_client_shVUlIjFLk,
  webgains_client_Lxdy7sDJ3W
]