
// @ts-nocheck


export const localeCodes =  [
  "de_AT",
  "de_DE",
  "de_CH",
  "fr_CH",
  "it_CH",
  "en_GB",
  "en_US",
  "fr_FR",
  "it_IT",
  "sv_SE",
  "da_DK",
  "nl_NL",
  "en_INT"
]

export const localeLoaders = {
  "de_AT": [{ key: "../lang/de.ts", load: () => import("../lang/de.ts?hash=0fd7b659&locale=de_AT" /* webpackChunkName: "locale__usr_src_app_lang_de_ts" */), cache: false },
{ key: "../lang/de_AT.ts", load: () => import("../lang/de_AT.ts" /* webpackChunkName: "locale__usr_src_app_lang_de_AT_ts" */), cache: true }],
  "de_DE": [{ key: "../lang/de.ts", load: () => import("../lang/de.ts?hash=0fd7b659&locale=de_AT" /* webpackChunkName: "locale__usr_src_app_lang_de_ts" */), cache: false },
{ key: "../lang/de_DE.ts", load: () => import("../lang/de_DE.ts" /* webpackChunkName: "locale__usr_src_app_lang_de_DE_ts" */), cache: true }],
  "de_CH": [{ key: "../lang/de.ts", load: () => import("../lang/de.ts?hash=0fd7b659&locale=de_AT" /* webpackChunkName: "locale__usr_src_app_lang_de_ts" */), cache: false },
{ key: "../lang/de_CH.ts", load: () => import("../lang/de_CH.ts" /* webpackChunkName: "locale__usr_src_app_lang_de_CH_ts" */), cache: true }],
  "fr_CH": [{ key: "../lang/fr.ts", load: () => import("../lang/fr.ts?hash=2fcb9d3a&locale=fr_CH" /* webpackChunkName: "locale__usr_src_app_lang_fr_ts" */), cache: false },
{ key: "../lang/fr_CH.ts", load: () => import("../lang/fr_CH.ts" /* webpackChunkName: "locale__usr_src_app_lang_fr_CH_ts" */), cache: true }],
  "it_CH": [{ key: "../lang/it.ts", load: () => import("../lang/it.ts?hash=e4becbdc&locale=it_CH" /* webpackChunkName: "locale__usr_src_app_lang_it_ts" */), cache: false }],
  "en_GB": [{ key: "../lang/en.ts", load: () => import("../lang/en.ts?hash=4958731f&locale=en_GB" /* webpackChunkName: "locale__usr_src_app_lang_en_ts" */), cache: false },
{ key: "../lang/en_GB.ts", load: () => import("../lang/en_GB.ts" /* webpackChunkName: "locale__usr_src_app_lang_en_GB_ts" */), cache: true }],
  "en_US": [{ key: "../lang/en.ts", load: () => import("../lang/en.ts?hash=4958731f&locale=en_GB" /* webpackChunkName: "locale__usr_src_app_lang_en_ts" */), cache: false },
{ key: "../lang/en_US.ts", load: () => import("../lang/en_US.ts" /* webpackChunkName: "locale__usr_src_app_lang_en_US_ts" */), cache: true }],
  "fr_FR": [{ key: "../lang/fr.ts", load: () => import("../lang/fr.ts?hash=2fcb9d3a&locale=fr_CH" /* webpackChunkName: "locale__usr_src_app_lang_fr_ts" */), cache: false }],
  "it_IT": [{ key: "../lang/it.ts", load: () => import("../lang/it.ts?hash=e4becbdc&locale=it_CH" /* webpackChunkName: "locale__usr_src_app_lang_it_ts" */), cache: false }],
  "sv_SE": [{ key: "../lang/sv.ts", load: () => import("../lang/sv.ts?hash=207dc227&locale=sv_SE" /* webpackChunkName: "locale__usr_src_app_lang_sv_ts" */), cache: false }],
  "da_DK": [{ key: "../lang/da.ts", load: () => import("../lang/da.ts?hash=54626864&locale=da_DK" /* webpackChunkName: "locale__usr_src_app_lang_da_ts" */), cache: false }],
  "nl_NL": [{ key: "../lang/nl.ts", load: () => import("../lang/nl.ts?hash=bc3d44f7&locale=nl_NL" /* webpackChunkName: "locale__usr_src_app_lang_nl_ts" */), cache: false }],
  "en_INT": [{ key: "../lang/en.ts", load: () => import("../lang/en.ts?hash=4958731f&locale=en_GB" /* webpackChunkName: "locale__usr_src_app_lang_en_ts" */), cache: false },
{ key: "../lang/en_INT.ts", load: () => import("../lang/en_INT.ts" /* webpackChunkName: "locale__usr_src_app_lang_en_INT_ts" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "i18n_config_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "de_AT",
      "countryISO2": "at",
      "iso": "de-at",
      "name": "Österreich",
      "files": [
        "lang/de.ts",
        "lang/de_AT.ts"
      ]
    },
    {
      "code": "de_DE",
      "countryISO2": "de",
      "iso": "de-de",
      "name": "Deutschland",
      "files": [
        "lang/de.ts",
        "lang/de_DE.ts"
      ]
    },
    {
      "code": "de_CH",
      "countryISO2": "ch",
      "iso": "de-ch",
      "name": "Schweiz",
      "files": [
        "lang/de.ts",
        "lang/de_CH.ts"
      ]
    },
    {
      "code": "fr_CH",
      "countryISO2": "ch",
      "iso": "fr-ch",
      "name": "Suisse",
      "files": [
        "lang/fr.ts",
        "lang/fr_CH.ts"
      ]
    },
    {
      "code": "it_CH",
      "countryISO2": "ch",
      "iso": "it-ch",
      "name": "Svizzera",
      "files": [
        "lang/it.ts"
      ]
    },
    {
      "code": "en_GB",
      "countryISO2": "gb",
      "iso": "en-gb",
      "name": "United Kingdom",
      "files": [
        "lang/en.ts",
        "lang/en_GB.ts"
      ]
    },
    {
      "code": "en_US",
      "countryISO2": "us",
      "iso": "en-us",
      "name": "United States",
      "files": [
        "lang/en.ts",
        "lang/en_US.ts"
      ]
    },
    {
      "code": "fr_FR",
      "countryISO2": "fr",
      "iso": "fr-fr",
      "name": "France",
      "files": [
        "lang/fr.ts"
      ]
    },
    {
      "code": "it_IT",
      "countryISO2": "it",
      "iso": "it-it",
      "name": "Italia",
      "files": [
        "lang/it.ts"
      ]
    },
    {
      "code": "sv_SE",
      "countryISO2": "se",
      "iso": "sv",
      "name": "Sweden",
      "files": [
        "lang/sv.ts"
      ]
    },
    {
      "code": "da_DK",
      "countryISO2": "dk",
      "iso": "da",
      "name": "Denmark",
      "files": [
        "lang/da.ts"
      ]
    },
    {
      "code": "nl_NL",
      "countryISO2": "nl",
      "iso": "nl",
      "name": "Netherlands",
      "files": [
        "lang/nl.ts"
      ]
    },
    {
      "code": "en_INT",
      "countryISO2": "ie",
      "iso": "en",
      "name": "International",
      "files": [
        "lang/en.ts",
        "lang/en_INT.ts"
      ]
    }
  ],
  "defaultLocale": "en_INT",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "lang",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "de_AT",
    "countryISO2": "at",
    "iso": "de-at",
    "name": "Österreich",
    "files": [
      {
        "path": "lang/de.ts"
      },
      {
        "path": "lang/de_AT.ts"
      }
    ]
  },
  {
    "code": "de_DE",
    "countryISO2": "de",
    "iso": "de-de",
    "name": "Deutschland",
    "files": [
      {
        "path": "lang/de.ts"
      },
      {
        "path": "lang/de_DE.ts"
      }
    ]
  },
  {
    "code": "de_CH",
    "countryISO2": "ch",
    "iso": "de-ch",
    "name": "Schweiz",
    "files": [
      {
        "path": "lang/de.ts"
      },
      {
        "path": "lang/de_CH.ts"
      }
    ]
  },
  {
    "code": "fr_CH",
    "countryISO2": "ch",
    "iso": "fr-ch",
    "name": "Suisse",
    "files": [
      {
        "path": "lang/fr.ts"
      },
      {
        "path": "lang/fr_CH.ts"
      }
    ]
  },
  {
    "code": "it_CH",
    "countryISO2": "ch",
    "iso": "it-ch",
    "name": "Svizzera",
    "files": [
      {
        "path": "lang/it.ts"
      }
    ]
  },
  {
    "code": "en_GB",
    "countryISO2": "gb",
    "iso": "en-gb",
    "name": "United Kingdom",
    "files": [
      {
        "path": "lang/en.ts"
      },
      {
        "path": "lang/en_GB.ts"
      }
    ]
  },
  {
    "code": "en_US",
    "countryISO2": "us",
    "iso": "en-us",
    "name": "United States",
    "files": [
      {
        "path": "lang/en.ts"
      },
      {
        "path": "lang/en_US.ts"
      }
    ]
  },
  {
    "code": "fr_FR",
    "countryISO2": "fr",
    "iso": "fr-fr",
    "name": "France",
    "files": [
      {
        "path": "lang/fr.ts"
      }
    ]
  },
  {
    "code": "it_IT",
    "countryISO2": "it",
    "iso": "it-it",
    "name": "Italia",
    "files": [
      {
        "path": "lang/it.ts"
      }
    ]
  },
  {
    "code": "sv_SE",
    "countryISO2": "se",
    "iso": "sv",
    "name": "Sweden",
    "files": [
      {
        "path": "lang/sv.ts"
      }
    ]
  },
  {
    "code": "da_DK",
    "countryISO2": "dk",
    "iso": "da",
    "name": "Denmark",
    "files": [
      {
        "path": "lang/da.ts"
      }
    ]
  },
  {
    "code": "nl_NL",
    "countryISO2": "nl",
    "iso": "nl",
    "name": "Netherlands",
    "files": [
      {
        "path": "lang/nl.ts"
      }
    ]
  },
  {
    "code": "en_INT",
    "countryISO2": "ie",
    "iso": "en",
    "name": "International",
    "files": [
      {
        "path": "lang/en.ts"
      },
      {
        "path": "lang/en_INT.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
