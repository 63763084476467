export type GlobalHelpers = {
    generateLocalePath: (input: string | undefined) => string | undefined;
};

export default defineNuxtPlugin((nuxtApp) => {
    const localePath = useLocalePathWoom();

    const helpers: GlobalHelpers = {
        generateLocalePath: (input: string | undefined) => {
            if (!input) return;

            // don't generate a locale path if what we got was an absolute link [usually an external link]
            if (input.startsWith('http') || input.startsWith('#')) {
                return input;
            }

            const output = localePath(input);

            const inputFirstSegment = input.split('/').find((x) => x.length > 0);

            const outputFirstSegment = output.split('/').find((x) => x.length > 0);

            // if generated url has the same starting segment as our output, the locale path was already applied
            // so just return the input unchanged
            if (inputFirstSegment === outputFirstSegment) {
                return input;
            }

            // otherwise, return the new url with locale applied
            return output;
        },
    };

    nuxtApp.provide('helpers', helpers);

    // so we can use these as template helpers
    nuxtApp.vueApp.use({
        install() {
            // @ts-ignore
            nuxtApp.vueApp.helpers = helpers;
        },
    });
});
