import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
    const router = useRouter();

    const config = useRuntimeConfig();
    const sentry = config.public.sentry;

    if (!sentry.dsn) {
        return;
    }

    Sentry.init({
        app: nuxtApp.vueApp,
        dsn: sentry.dsn,
        environment: sentry.environment,
        release: sentry.versionNumber,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            // new Sentry.Replay(), // Is not available in our sentry plan
        ],

        trackComponents: true,
        // https://docs.sentry.io/platforms/javascript/guides/vue/configuration/filtering/#decluttering-sentry
        allowUrls: [/https?:\/\/([a-zA-Z0-9-\.])*website-6po\.pages\.dev/, /https?:\/\/((staging|preview)\.)?woom\.com/],
        // https://gist.github.com/impressiver/5092952
        denyUrls: [
            // Facebook flakiness
            /graph\.facebook\.com/i,
            // Facebook blocked
            /connect\.facebook\.net\/en_US\/all\.js/i,
            // Woopra flakiness
            /eatdifferent\.com\.woopra-ns\.com/i,
            /static\.woopra\.com\/js\/woopra\.js/i,
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            // Other plugins
            /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
            /webappstoolbarba\.texthelp\.com\//i,
            /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        ],
        ignoreErrors: [
            // https://onewoom.atlassian.net/browse/ECOMM-946
            // CSS preload seems to be blocked by some browsers
            'Unable to preload CSS',
            // Happens in AsyncComponentWrapper (nuxt internal) mostly on Safari
            'Importing a module script failed',
            // Happens in AsyncComponentWrapper (nuxt internal) mostly on Chrome
            // catch both errors: 'error loading dynamically imported module', 'Failed to fetch dynamically imported module'
            'dynamically imported module',
            // https://onewoom.atlassian.net/browse/ECOMM-1489
            // Valid errors
            'No valid customer',
            /^No shopify config found for country/,
        ],

        tracesSampleRate: config.production ? 0.5 : 1, // Change in prod

        // replaysSessionSampleRate: 0, // Change in prod if necessary
        // replaysOnErrorSampleRate: 0, // Change in prod if necessary
    });

    Sentry.setTag('server', false);
    if (config.public.CF_PAGES_URL?.length) {
        Sentry.setTag('CF_PAGES_URL', config.public.CF_PAGES_URL);
    }
    if (config.public.AWS_DEPLOYMENT_ID?.length) {
        Sentry.setTag('AWS_DEPLOYMENT_ID', config.public.AWS_DEPLOYMENT_ID);
    }
    Sentry.setTag('GIT_COMMIT_SHA', config.public.GIT_COMMIT_SHA);
    Sentry.setTag('GIT_BRANCH', config.public.GIT_BRANCH);
});
