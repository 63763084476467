import validate from "/usr/src/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.41_eslint@8.57.0_ioredis@5.4.1_magicast@0_sp6oxyoamdsk3zyrb4wztbq7li/node_modules/nuxt/dist/pages/runtime/validate.js";
import trailing_45slash_45global from "/usr/src/app/middleware/trailing-slash.global.ts";
import manifest_45route_45rule from "/usr/src/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.41_eslint@8.57.0_ioredis@5.4.1_magicast@0_sp6oxyoamdsk3zyrb4wztbq7li/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auth-guard": () => import("/usr/src/app/middleware/authGuard.ts"),
  kvbindingmiddleware: () => import("/usr/src/app/middleware/kvbindingmiddleware.ts")
}