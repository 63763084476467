<script setup lang="ts">
    defineProps<{
        error: Record<string, any>;
    }>();
    useHead({
        bodyAttrs: {
            class: 'overflow-x-hidden',
        },
    });
</script>
<template>
    <div>
        <mol-error :status-code="error.statusCode" />
    </div>
</template>
