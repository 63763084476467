<script lang="ts">
    /**
     * Docs 📘:
     * - https://www.sketch.com/s/d5c5b81a-6387-4b69-a27e-19a117a47eb4/a/My7Vvya
     * - https://www.sketch.com/s/d5c5b81a-6387-4b69-a27e-19a117a47eb4/a/WKWjRKw
     */
    const levelMap = {
        h1: 'xl',
        h2: 'lg',
        h3: 'md',
        h4: 'sm',
        h5: 'xs',
        span: 'span',
    } as const;
</script>
<script setup lang="ts">
    interface HeadingProps {
        /**
         * default level: 'h2'
         */
        level?: keyof typeof levelMap;
        size?: '2xl' | (typeof levelMap)[keyof typeof levelMap];
        /**
         * default: true
         */
        fontBold?: boolean;
    }

    const props = withDefaults(defineProps<HeadingProps>(), {
        level: 'h2',
        fontBold: true,
    });
    const appStore = useAppStore();
    const overrideH1 = ref(false);
    const selectedSize = computed(() => {
        return props.size || levelMap[props.level] || 'lg';
    });
    onBeforeMount(() => {
        // Make sure only one h1 is displayed per page
        if (props.level !== 'h1') return;
        if (appStore.hasH1) overrideH1.value = true;
        appStore.hasH1 = true;
    });
</script>
<template>
    <component
        :is="overrideH1 ? 'h2' : level"
        :class="{
            'font-bold': fontBold,
            // TODO: verify that text-4xl is too much for mobile
            'text-3xl md:text-5xl': selectedSize === '2xl', // Display
            'text-3xl md:text-4xl': selectedSize === 'xl', // h1 default
            'text-2xl md:text-3xl': selectedSize === 'lg', // h2 default
            'text-xl md:text-2xl': selectedSize === 'md', // h3 default
            'text-lg md:text-xl': selectedSize === 'sm', // h4 default
            'text-base': selectedSize === 'xs', // h5 default
        }">
        <slot></slot>
    </component>
</template>
